import { Chip } from "@suid/material";
import { AddIcon } from '../icons';

import { TextField, IconButton } from "@suid/material";

import { getText } from '../SmallComponents/Translate'
import { createSignal, createEffect, JSXElement, For, Show } from "solid-js";
import { createStore } from "solid-js/store";

interface ChipData {
    icon?: JSXElement;
    key: number;
    label: string;
}

const Tags = () =>{

    const txtAdd = getText("smallcomponent", "addtag")

    const [newChipString, setNewChipString] = createSignal<null | string>(txtAdd() as string);
    const [showAddNewChip, setShowAddNewChip] = createSignal(false);
    const [isLeavingFocus, setIsLeavingFocus] = createSignal(false);
    const [chipData, setChipData] = createStore({
        chipArray: [
            { key: 0, icon: <AddIcon />, label: txtAdd() as string },
            { key: 1, label: 'Gold' },
            { key: 2, label: 'Upgrade' },
            { key: 3, label: 'S/4' },
        ]
    });

    const handleChipDelete = (chipToDelete: ChipData) => () => {
        setChipData('chipArray', chips => chips.filter((chip: ChipData) => chip.key !== chipToDelete.key));
    };

    const handleAddChip = () => {
        setShowAddNewChip(true)
    };

    const handleCloseAddNewChip = () => {
        const currentChips = chipData.chipArray.slice();
        currentChips.push({ key: currentChips.length, label: newChipString() as string });
        setChipData('chipArray', currentChips);
        setNewChipString(txtAdd() as string);
        setShowAddNewChip(false);
    }
   
const handleLeaveFocus = (event: Event) => {
    setNewChipString(txtAdd() as string);
    setShowAddNewChip(false);
};
    
    const ChipInput = () => (
        <TextField

            required
            id="addchip"
            type="search"
            size="small"
            variant="standard"

            value={newChipString()}
            onBlur={handleLeaveFocus}
            onChange={(e) => setNewChipString(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    handleCloseAddNewChip();
                    e.preventDefault();  // to ensure that the event doesn't cause onBlur
                }
            }}

        />
    ); 
    return (
        <>
            <For each={chipData.chipArray}>
                {(option: ChipData) => (
                    <>
                        <Show when={option.key === 0} fallback={
                            <Chip size="small" label={option.label} onDelete={handleChipDelete(option)} variant="outlined" />
                        }>
                            <Show when={showAddNewChip()} fallback={
                                <Chip icon={option.icon} size="small" label={newChipString()} onClick={handleAddChip} variant="outlined" />
                            }>
                                <ChipInput />
                            </Show>
                        </Show>
                    </>
                )}
            </For>
        </>
    )
}

export { Tags }